/* General Styling */
.contact-page {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 50px 20px;
  background: #f9f9f9;
  text-align: center;
}

.contact-page h1 {
  font-size: 3rem;
  margin-bottom: 50px;
  color: #8B0000;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Contact Sections */
.contact-sections {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

/* Contact Information */
.contact-info {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  flex: 1;
  text-align: left;
}

.contact-info h2 {
  font-size: 2rem;
  color: #1e3c72;
  margin-bottom: 20px;
}

.info-item {
  margin-bottom: 20px;
}

.info-item p {
  margin: 10px 0 5px;
  font-weight: bold;
  color: #333;
 
}

.info-item a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s;
  padding: 20px;
}

.info-item a:hover {
  color: #ff5733;
}

.icon {
  color: #1e3c72;
  margin-right: 10px;
  font-size: 1.5rem;
}

/* Contact Form */
.contact-form {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  flex: 2;
}

.contact-form h2 {
  font-size: 2rem;
  color: #1e3c72;
  margin-bottom: 20px;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form input, .contact-form textarea {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s;
}

.contact-form input:focus, .contact-form textarea:focus {
  border-color: #1e3c72;
}

.contact-form button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(135deg, #ff5733, #ff6b6b);
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.contact-form button:hover {
  background: linear-gradient(135deg, #ff6b6b, #ff5733);
}

/* Social Media Links */
.contact-social {
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  max-width: 350px;
  flex: 1;
  text-align: center;
}

.map-container iframe{
  height: 250px;
  width: 250px;
  margin-bottom: 10px;
}

.contact-social h3 {
  font-size: 2rem;
  color: #1e3c72;
  margin-bottom: 10px;
}

.social-icons3 {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 2rem;
}

.social-icons3 a {
  color:  #1e3c72;
  transition: color 0.3s;
}

.social-icons3 a:hover {
  color: #ff5733;
}

.follow-us h2{
  font-size: 2rem;
  color: #1e3c72;
  margin-bottom: 20px;

}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-page h1 {
    font-size: 2.5rem;
  }

  .contact-sections {
    flex-direction: column;
    align-items: center;
  }

  .contact-info, .contact-form, .contact-social {
    max-width: 100%;
  }

  .contact-form h2, .contact-info h2, .contact-social h2 {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .contact-page h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .contact-info, .contact-form, .contact-social {
    padding: 20px;
  }

  .contact-form input, .contact-form textarea {
    font-size: 0.9rem;
  }

  .contact-form button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-page h1 {
    font-size: 1.8rem;
  }

  .icon {
    font-size: 1.2rem;
  }

  .social-icons {
    gap: 15px;
    font-size: 1.5rem;
  }
}
