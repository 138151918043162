/* Footer Section */
.footer {
    padding: 30px 20px;
    background-color: #55555511;
    color: black;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-left, .footer-center, .footer-right {
    width: 30%;
    min-width: 250px;
    margin-bottom: 20px;
  }
  
  /* Footer Left - Company Info */
  .footer-left {
    text-align: left;
  }
  
  .footer-logo {
    width: 100px;
    margin-bottom: 20px;
  }
  
  .footer-left p {
    font-size: 14px;
    line-height: 1.6;
    color: black;
  }
  
  /* Footer Center - Quick Links */
  .footer-center {
    text-align: left;
  }
  
  .footer-center h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #1e3c72;
    border-bottom: 2px solid #ff5733;
    padding-bottom: 5px;
    display: inline-block;
  }
  
  .footer-center ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-center ul li {
    margin-bottom: 10px;
  }
  
  .footer-center ul li a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-center ul li a:hover {
    color: #ff5733;
  }
  
  /* Footer Right - Contact and Social */
  .footer-right {
    text-align: left;
  }
  
  .footer-right h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #1e3c72;
    border-bottom: 2px solid #ff5733;
    padding-bottom: 5px;
    display: inline-block;
  }
  
  .footer-right p {
    margin-bottom: 10px;
    font-size: 14px;
    color: black;
  }
  
  /* Social Icons */
.social-icons {
  display: flex;
  justify-content: flex-start; /* Aligns icons to the left */
  gap: 15px;
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.075);
  transition: transform 0.6s ease, background-color 0.3s ease;
}

.social-icons a:hover {
  background-color: #ff6b6b;
  transform: scale(1.1);
}

.social-icons img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

  
  /* Footer Bottom */
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #444;
    color: black;
    font-size: 14px;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-left, .footer-center, .footer-right {
      width: 100%;
    }
  }
  