/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Prevent horizontal scrolling */
html, body {
  width: 100%;
  overflow-x: hidden;
}

/* Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  z-index: 2;

  transition: background-color 0.3s ease;  /* Smooth background transition */
}

.navbar.scrolled {
  background-color: #ffffff; /* Background when scrolled */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Add shadow when scrolled */
}

/* Logo Styling */
.navbar-logo {
  display: flex;
  align-items: center;
}

.company-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

/* Navbar Links for Desktop View */
.navbar-links {
  list-style: none;
  display: flex;
  margin-left: auto;
  padding: 0;
  align-items: center;
  
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 15px;
  
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
  background: linear-gradient(135deg, #ff0000, #8b0000);
  border-radius: 20px;
  padding: 12px 20px;
}

.navbar-links li a:hover {
  color: red;
  transform: translateY(-2px);  /* Slight lift effect on hover */
}

/* Hamburger Menu for Mobile View */
.hamburger {
  display: none;
}

/* Sticky Navbar Transition on Scroll */
.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);  /* Slightly stronger shadow when scrolled */
}

/* Responsive Navbar - Mobile View */
@media (max-width: 768px) {
  /* Navbar layout for mobile view */
  .navbar {
    padding: 15px 20px;
  }

  /* Show Hamburger Icon */
  .hamburger {
    display: block;
    font-size: 30px;
    color: #8B0000;
    cursor: pointer;
  }

  /* Dropdown Menu */
  .navbar-links {
    flex-direction: column;
    width: 100%;
    height: 0;
    overflow: hidden;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: height 0.3s ease;
    padding: 10px 0;
    z-index: 999;
  }

  /* Dropdown Active State */
  .navbar-links.active {
    display: flex;
    height: auto;  /* Allows for dynamic height based on content */
  }

  .navbar-links li {
    margin: 15px 0;
    text-align: center;
    width: 100%;
  }

  .navbar-links li a {
    font-size: 16px;
    color: #8B0000;
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}
