/* General Styles */
.services-page {
  font-family: Arial, sans-serif;
  padding: 50px 20px;
  background: #f5f5f5;
  color: #333;
  text-align: center;
}

.services-page h1 {
  font-size: 3rem;
  margin-bottom: 60px;
  color: #1e3c72;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

/* Service Section */
.service-section {
  background: #fff;
  padding: 50px 30px;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.service-section.even {
  background: linear-gradient(135deg, #e9f8ff, #fdfbff);
}

.service-section.odd {
  background: linear-gradient(135deg, #fffaf0, #ffebeb);
}

.service-section:hover {
  transform: scale(1.02);
}

.service-section h2 {
  font-size: 2.5rem;
  color: #ff5733;
  margin-bottom: 30px;
}

/* Problem Statement and Solution */
.problem-statement, .solution {
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  text-align: left;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.problem-statement h3, .solution h3 {
  font-size: 1.8rem;
  color: #1e3c72;
  margin-bottom: 15px;
}

.problem-statement p, .solution p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.7;
}

.problem-statement:hover, .solution:hover {
  background-color: #f0f4f8;
}

/* Service Images with Grid Layout */
.service-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.service-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-image:hover {
  transform: scale(1.05);
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media (max-width: 1024px) {
  .services-page h1 {
    font-size: 2.5rem;
  }

  .service-section {
    padding: 40px 20px;
  }

  .problem-statement, .solution {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .services-page h1 {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .service-section h2 {
    font-size: 2rem;
  }

  .problem-statement, .solution {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .service-section {
    padding: 30px 15px;
  }

  .problem-statement, .solution {
    padding: 10px;
  }

  .service-images {
    grid-template-columns: 1fr;
  }
}
