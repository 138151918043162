/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  width: 100%;
  overflow-x: hidden;
  font-family: 'Arial', sans-serif;
  margin-top: 42px;
}

/* Hero Section */

.hero-container {
  text-align: center;
}

/* Common Heading Styling */
.hero-heading {
  font-size: 2.8rem;
  color: #1e3c72; /* Dark teal color */
  font-weight: bold;
  margin-top: 50px;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0f0;
}

.hero-image {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  overflow: hidden;
}

.hero-image img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
  border-radius: 10px;
}

.hero-content {
  margin-left: 30px;
  max-width: 600px;
  text-align: left;
  background-color: white !important;
}

/* Animation for Points */
.hero-content ul {
  list-style-type: none;
  padding-left: 0;
  
}

.hero-content ul li {
  font-size: 1.2rem;
  margin-bottom: 8px;
  line-height: 1.6;
  color: black;
  padding: 10px 15px;
  border-radius: 8px;
  font-weight: bold;
  opacity: 0; /* Hidden initially */
  transform: translateX(-50px); /* Positioned to slide in from the left */
  animation: fadeInRight 0.8s ease forwards;
  
}

.hero-content ul li::before {
  content: "➔"; /* Arrow character */
  position: absolute;
  left: -25px; /* Adjust for spacing */
  color: #cc0000; /* Customize arrow color */
  font-size: 1.3rem; /* Adjust arrow size */
}

/* Keyframes for Fade-In and Slide-Left Animation */
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px); /* Start off-screen to the right */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Settle into place */
  }
}

/* Staggered Delays for Sequential Effect */
.hero-content ul li:nth-child(1) { animation-delay: 0.3s; }
.hero-content ul li:nth-child(2) { animation-delay: 0.6s; }
.hero-content ul li:nth-child(3) { animation-delay: 0.9s; }
.hero-content ul li:nth-child(4) { animation-delay: 1.2s; }
.hero-content ul li:nth-child(5) { animation-delay: 1.5s; }
.hero-content ul li:nth-child(6) { animation-delay: 1.7s; }

/* Responsive Adjustments for Mobile View */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column; /* Stack image above content */
    padding: 20px;
  }

  .hero-image img {
    max-width: 90%; /* Adjust image width on mobile */
    height: auto; /* Set to auto for better scaling */
    margin-bottom: 20px;
    margin-left: 15px;
  }

  .hero-content {
    margin-left: 0;
    text-align: center; /* Center align text for mobile */
  }

  .hero-content h2 {
    font-size: 2rem; /* Slightly reduce heading size */
  }

  .hero-content ul li {
    font-size: 1rem; /* Reduce font size for points */
    text-align: center; /* Center align each point */
    margin: 0 auto 15px; /* Adjust margin for spacing */
  }
}

/* About Us Preview Section */
.about-us-preview {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(120deg, #ffffff, #f0f4f8);
  color: #333;
  opacity: 0; /* Initial state for fade-in */
  transform: translateX(-50px); /* Start slightly to the left */
  animation: slideInFade 1.5s ease forwards; /* Trigger animation */
}

.about-us-preview h2 {
  font-size: 2.5rem;
  color: #8B0000;
  margin-bottom: 20px;
}

.about-us-preview p {
  font-size: 1.125rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto 30px;
  line-height: 1.8;
}

.about-us-button {
  padding: 12px 24px;
  background: linear-gradient(135deg, #ff0000, #8b0000);
  color: white;
  border-radius: 30px;
  font-size: 1.125rem;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-us-button:hover {
  transform: translateY(-3px);
  box-shadow: 0px 8px 16px rgba(255, 0, 0, 0.4);
}

/* Slide-in and Fade Animation */
@keyframes slideInFade {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Starts slightly off-screen */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Ends in natural position */
  }
}

/* Services Overview Section */
.services-overview {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(120deg, #ffffff, #f0f4f8);
  color: #8B0000;
}

.services-overview h2 {
  font-size: 2.5rem;
  color: #8B0000;
  margin-bottom: 40px;
}

.service-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 20px;
  opacity: 0; /* Initial hidden state for animation */
  transform: translateY(30px); /* Slide effect */
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.service-row.show {
  opacity: 1;
  transform: translateY(0); /* Final state */
}

.service-row .service-img {
  flex: 1;
  max-width: 45%;
  transform: translateX(-50px);
  transition: transform 0.6s ease;
}

.service-row.show .service-img {
  transform: translateX(0);
}

.service-row.reverse .service-img {
  margin-left: 20px;
  transform: translateX(50px);
}

.service-row.show.reverse .service-img {
  transform: translateX(0);
}

.service-row .service-img img {
  width: 100%;
  max-width: 300px;
  border-radius: 20px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

.service-row .service-desc {
  flex: 1;
  max-width: 45%;
  padding: 10px;
  transform: translateY(20px);
  transition: transform 0.6s ease, opacity 0.8s ease;
}

.service-row.show .service-desc {
  transform: translateY(0);
}

.service-row .service-desc h3 {
  font-size: 1.8rem;
  color: #1e3c72;
  margin-bottom: 15px;
}

.service-row .service-desc p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .services-overview h2 {
    font-size: 2rem;
  }

  .service-row .service-desc h3 {
    font-size: 1.5rem;
  }

  .service-row .service-desc p {
    font-size: 1rem;
  }

  .service-row .service-img img {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .service-row {
    flex-direction: column; /* Stack image and description vertically */
    align-items: center;
    text-align: center;
  }

  .service-row .service-img,
  .service-row .service-desc {
    max-width: 100%;
    text-align: center;
  }

  .service-row .service-desc {
    margin-top: 20px;
  }

  .services-overview h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .service-row .service-desc h3 {
    font-size: 1.6rem;
  }

  .service-row .service-desc p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .services-overview {
    padding: 30px 10px;
  }

  .services-overview h2 {
    font-size: 1.5rem;
  }

  .service-row .service-desc h3 {
    font-size: 1.3rem;
  }

  .service-row .service-desc p {
    font-size: 0.9rem;
  }

  .service-row .service-img img {
    max-width: 200px;
  }
}

/* Key Features/Benefits Section */
.key-features {
  padding: 60px 20px;
  text-align: center;
  color: #8B0000;
}

.key-features h2 {
  font-size: 2.5rem;
  color: #8B0000; /* Light red for title */
  margin-bottom: 40px;
  font-weight: bold;
}

/* Feature Items Grid */
.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Feature Item Card */
.feature-item {
  background: linear-gradient(145deg, #ffffff, #f1f1f1); /* Soft gradient background for cards */
  border-radius: 15px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
  padding: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Card Hover Effect */
.feature-item:hover {
  transform: translateY(-8px); /* Lift effect */
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Feature Icon/Image */
.feature-item img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
  border-radius: 20px;
}

.feature-item:hover img {
  transform: scale(1.1); /* Slight zoom on hover */
}

/* Feature Title */
.feature-item h3 {
  font-size: 1.5rem;
   color: #1e3c72;
  margin-bottom: 10px;
  font-weight: 600;
}

/* Feature Description */
.feature-item p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .key-features h2 {
    font-size: 2rem;
  }

  .feature-item {
    padding: 20px;
  }

  .feature-item h3 {
    font-size: 1.4rem;
  }

  .feature-item p {
    font-size: 0.95rem;
  }
}



/* Trusted By Section */
.trusted-by {
  padding: 60px 20px;
  text-align: center;
  background: #ffffff;
}

.trusted-by h2 {
  font-size: 2.5rem;
  color: #8B0000;
  margin-bottom: 40px;
  font-weight: bold;
}

.trusted-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.trusted-logos img {
  width: 150px;
  height: auto;
  transition: transform 0.3s ease;
}

.trusted-logos img:hover {
  transform: scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .key-features h2, .trusted-by h2 {
    font-size: 2rem;
  }

  .feature-item {
    padding: 20px;
  }

  .trusted-logos img {
    width: 120px;
  }
}

/* Footer Section */
.footer {
  padding: 30px 20px;
  background-color: #55555511;
  color: black;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-left, .footer-center, .footer-right {
  width: 30%;
  min-width: 250px;
  margin-bottom: 20px;
}

/* Footer Left - Company Info */
.footer-left {
  text-align: left;
}

.footer-logo {
  width: 100px;
  margin-bottom: 20px;
}

.footer-left p {
  font-size: 14px;
  line-height: 1.6;
  color: black;
}

/* Footer Center - Quick Links */
.footer-center {
  text-align: left;
}

.footer-center h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #1e3c72;
  border-bottom: 2px solid #ff5733;
  padding-bottom: 5px;
  display: inline-block;
}

.footer-center ul {
  list-style: none;
  padding: 0;
}

.footer-center ul li {
  margin-bottom: 10px;
}

.footer-center ul li a {
  color: black;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-center ul li a:hover {
  color: #ff5733;
}

/* Footer Right - Contact and Social */
.footer-right {
  text-align: left;
}

.footer-right h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #1e3c72;
  border-bottom: 2px solid #ff5733;
  padding-bottom: 5px;
  display: inline-block;
}

.footer-right p {
  margin-bottom: 10px;
  font-size: 14px;
  color: black;
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 10px;
  
}

.social-icons a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.192);
  transition: transform 0.6s ease, background-color 0.3s ease;
}

.social-icons a:hover {
  background-color: #ff5733;
  transform: scale(1.1);
}

.social-icons img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.social-icons a:hover {
  background-color: #ff6b6b;
  transform: scale(1.1);
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #444;
  color: black;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-left, .footer-center, .footer-right {
    width: 100%;
  }
}
