/* About Page Base */
.about-page {
  font-family: Arial, sans-serif;
  color: #333;
}

/* Hero Section */

/* Container for video */
.about-video-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 100px); /* Adjusts for navbar height */
  overflow: hidden;
}


/* Fullscreen video */
.fullscreen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
 
}


@media (max-width: 768px) {
  .about-video-container {
    height: 50vh; /* Smaller height specifically for mobile view */
  }

  .fullscreen-video {
    object-fit: contain; /* Ensures no content is cut on mobile */
    background-color: black; /* Adds background color to fill space */
  }
}

@media (max-width: 480px) {
  .about-video-container {
    height: 40vh; /* Smaller height for very small screens */
  }
}


/* Mission, Vision, and Values */
.mv-values {
  padding: 60px 20px;
  text-align: center;
}

.mv-values-header h2 {
  font-size: 2.5rem;
  color: #8B0000;
  margin-bottom: 40px;
}

.mv-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}

.mv-card {
  width: 250px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.26);
  transition: transform 0.3s;
}

.mv-card h3 {
  color: #1e3c72;
}

.mv-card:hover {
  transform: translateY(-10px);
}

.mv-card img {
  width: 60px;
  margin-bottom: 15px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .mv-values {
    padding: 40px 15px;
  }

  .mv-values-header h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .mv-cards {
    flex-direction: column;
    align-items: center;
  }

  .mv-card {
    width: 90%; /* Full width for smaller screens */
    margin-bottom: 20px;
  }

  .mv-card h3 {
    font-size: 1.5rem;
  }
  
  .mv-card img {
    width: 50px; /* Smaller icon size */
  }
}

/* Timeline Section */
.timeline-section {
  padding: 50px 20px;
  text-align: center;
 
}

.timeline-section h2 {
  font-size: 2.5rem;
  color: #8B0000;
  margin-bottom: 80px;
}

.stairs-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 20px;
  position: relative;
}

.stair-step {
background-color: #1e3c72;
  height: 60px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.stair-step.active {
  background: linear-gradient(135deg, #ff0000, #8b0000);
  transform: translateY(-10px);
}

.stair-step .step-year {
  font-size: 1.2rem;
  color: #ffffff;
}

.person-icon {
  position: absolute;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  animation: walk 2s infinite;
}

@keyframes walk {
  0% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-10px); }
  100% { transform: translateX(-50%) translateY(0); }
}

/* Description Box */
.step-description {
  margin-top: 40px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.288);
  border-radius: 8px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.step-description h3 {
  font-size: 1.8rem;
  color:#1e3c72;
  margin-bottom: 10px;
}

.step-description p {
  font-size: 1rem;
  color: black;
}

/* Team Section Styling */
.team-section {
  padding: 80px 20px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  position: relative;
}

/* Title Styling */
.team-section h2 {
  font-size: 2.8rem;
  color: #8B0000;
  margin-bottom: 60px;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Team Grid */
.team-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* Team Card Flip Animation */
.team-member {
  perspective: 1000px;
}

.team-card {
  width: 250px;
  height: 250px;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  position: relative;
}

.team-card:hover {
  transform: rotateY(180deg);
}

/* Front and Back Card Styles */
.team-front, .team-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
}

.team-front {
  background: #ffffff;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.team-front h3 {
  margin-top: 15px;
  font-size: 1.5rem;
  color: #1e3c72;
}

.team-front p {
  font-size: 1rem;
  color: black;
}

.team-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
  transition: transform 0.3s;
}

.team-image:hover {
  transform: scale(1.05);
}

.team-back {
  background: #333;
  color: #fff;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
}

.team-back p {
  font-size: 0.95rem;
  margin-bottom: 15px;
  color: #ddd;
}

.social-icons {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.icon {
  font-size: 1.5rem;
}

.linkedin-icon {
  color: #1DA1F2;
}

.twitter-icon {
  color: #1DA1F2;
}

.github-icon {
  color: #1DA1F2;
}

/* Responsive Mobile Adjustments */
@media (max-width: 768px) {
  /* Scale down team card on mobile */
  .team-card {
    width: 80%;  /* Scale width */
    height: 200px;
  }

  .team-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .team-grid {
    flex-direction: column;
    gap: 50px;
    justify-content: center;
    margin-left: 50px;
  }
}
